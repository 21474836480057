import React from 'react';
import './MainPage.css';

const MainPage: React.FC = () => {
    return (
        <div className="finbotix-main">
            <header className="finbotix-header">
                <div className="finbotix-logo">Finbotix</div>
                <nav className="finbotix-nav">
                    <a href="#home" className="nav-link">Home</a>
                    <a href="#contact" className="nav-link">Contact Us</a>
                    <a href="#signup" className="nav-link nav-cta">Sign Up</a>
                </nav>
            </header>

            <section className="finbotix-hero">
                <div className="hero-content">
                    <h1 className="hero-title">Empower Your Financial Future with AI</h1>
                    <p className="hero-subtitle">
                        Finbotix analyzes your finances in real-time, offering personalized insights, alerts, and growth strategies.
                    </p>
                    <div className="hero-actions">
                        <button className="btn-primary">Get Started</button>
                        <a href="#learn-more" className="hero-link">Learn More</a>
                    </div>
                </div>
                <div className="hero-illustration"></div>
            </section>

            <section className="finbotix-features">
                <h2>Key Features</h2>
                <div className="features-grid">
                    <div className="feature-card">
                        <div className="feature-icon">💡</div>
                        <h3>Real-Time Alerts</h3>
                        <p>Stay informed with instant notifications about changes to your investments and spending patterns.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">🤖</div>
                        <h3>Personalized Insights</h3>
                        <p>Get tailor-made advice based on your financial habits, goals, and risk tolerance.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">📈</div>
                        <h3>Goal Tracking</h3>
                        <p>Monitor your progress toward financial milestones and let Finbotix help keep you on track.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">🔄</div>
                        <h3>Intelligent Rebalancing</h3>
                        <p>Automatically rebalance your portfolio according to market conditions and your preferences.</p>
                    </div>
                </div>
            </section>

            <section className="finbotix-testimonial">
                <blockquote>
                    “Finbotix transformed the way I manage my money. It’s like having a personal CFO in my pocket!”
                    <footer>— Jane Doe, Early Adopter</footer>
                </blockquote>
            </section>

            <footer className="finbotix-footer">
                <p>© {new Date().getFullYear()} Finbotix. All rights reserved.</p>
                <p><a href="#privacy">Privacy Policy</a></p>
            </footer>
        </div>
    );
};

export default MainPage;
